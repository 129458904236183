<template>
    <div class="custom-arrow-steps clearfix">
        <div class="step class-step bg-success"> 
            <div class="flex flex-row">
                <md-icon class="text-sm text-white mr-1">
                    text_snippet
                </md-icon>
                <div>
                    <div>
                        <h2 class="text-xs text-white font-light">CLASS NAME</h2>
                    </div>
                    <div>
                        <p class="text-xs font-semibold">{{ className }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="step module-step bg-dodger-blue">
            <div class="flex flex-row">
                <md-icon class="text-sm text-white mr-1">
                    menu_book
                </md-icon>
                <div>
                    <div>
                        <h2 class="text-xs text-white font-light">MODULE</h2>
                    </div>
                    <div>
                        <p class="text-xs font-semibold">{{ moduleName }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="step chapter-step bg-autumn-blaze">
            <div class="flex flex-row">
                <md-icon class="text-white text-sm mr-1" style="transform: rotate(45deg);">
                    attach_file
                </md-icon>
                <div>
                    <div>
                        <h2 class="text-xs text-white font-light">CHAPTER</h2>
                    </div>
                    <div>
                        <p class="text-xs font-semibold">{{ chapterName }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "HeaderBreadCrumbs",
    computed:{
        ...mapGetters({
            getClassDetail: "onlineClassStudentPart/getClassDetail",
            getSelectedModule: "onlineClassStudentPart/getSelectedModule",
            getSelectedChapterDetail: "onlineClassStudentPart/getSelectedChapterDetail",
        }),
        className(){
            return this.getClassDetail?.title;
        },
        moduleName(){
            return this.getSelectedModule?.title;
        },
        chapterName(){
            return this.getSelectedChapterDetail?.title;
        },
    },
}
</script>

<style lang="scss">
.custom-arrow-steps .step { 
    display: flex;
    align-items: center;
    height: 60px;
    box-sizing: border-box;
    color: #fff;
    cursor: default;
    margin: 0 2px;
    padding: 10px 10px 10px 30px;
    min-width: 180px;
    float: left;
    position: relative;
    /* background-color: #abc937; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
}
.custom-arrow-steps .step:after,
.custom-arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    z-index: 2;
    transition: border-color 0.2s ease;
    /* border-left: 17px solid #abc937; */
}

.class-step:after{
    border-left: 17px solid #36c037 !important;
}

.module-step:after{
    border-left: 17px solid #2E67FC !important;
}

.chapter-step:after{
    border-left: 17px solid #da9031 !important;
}
.custom-arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}
.custom-arrow-steps .step span {
    position: relative; 
}

.custom-arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.custom-arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.custom-arrow-steps .step.current {
    color: #fff;
    background-color: #82ad42;
}

.custom-arrow-steps .step.current:after {
    border-left: 17px solid #82ad42;
}
</style>