<template>
    <div class="studen_online_class_chapter_details">
        <section class="flex justify-between">
            <div class="flex">
                <md-icon class="text-3xl mr-3 text-sky banner-left-arrow cursor" @click.native="onBack()">arrow_back</md-icon>

                <div class="">
                    <HeaderBreadCrumbs />
                </div>
            </div>
            <div>
                <Button
                    class="h-10 pointer text-transform-none bg-algal pointer rounded text-white px-1 py-2 text-base font-semibold"
                    type="button"
                    label="Back to Home"    
                    @click.native="goToHome()"
                >
                </Button>
            </div>
        </section>

        <md-divider class="my-3"></md-divider>

        <section class="w-100p">
            <div class="flex flex-row">
                <div class="w-25p">
                    <ChapterDetailsSideNavbar />
                </div>

                <div class="w-72p ml-8">
                    <md-divider class="my-4 bg-light-blue"></md-divider>
                    
                    <ChapterProgress :is-lock="isLock" :classDetails="getClassDetail" :chapterDetail="getSelectedChapterDetail" />

                    <md-divider class="my-4 bg-light-blue"></md-divider>
    
                    <div v-if="!isLock">
                        <p class="my-4 w-100p text-sm font-semibold line-height-27" v-html="getSelectedChapterDetail?.description"></p>
    
                        <md-divider class="my-3"></md-divider>
                        
                        <div v-for="(part, index) in getSelectedChapterDetail?.parts" :key="index" class="mt-10">
                            <h2 class="text-lg font-bold flex"><span class="rounded-full bg-purply-magenta text-white w-4 text-xs text-center mr-1">{{index + 1}}</span> {{ part.title }}</h2>
                            <!-- <p class="mt-2 w-85p text-sm font-semibold line-height-27">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> -->
                            

                            <div class="ml-3 mt-2" v-for="(section, i) in part.sections" :key="i">
                                <h2 class="text-base text-capitalize">{{ index + 1 }}.{{i + 1}}. {{ section.type }}</h2>
                                <p class="mt-2 ml-2 w-85p text-sm line-height-27" v-html="section.instructions"></p>
                                
                                <div class="flex flex-row">
                                    <ChapterAudio v-if="section.type === 'material' && section.material_type === 'audio'" :item="section.material_url"/>
                                    <Videos v-else-if="section.type === 'material' && section.material_type === 'video'" :item="section.material_url"/>
                                    <ImageAndContents class="w-100p mr-1" v-else-if="section.type === 'material' && section.material_type === 'pdf'" :type="section.material_type" :items="section" :url="section.material_url"/>
                                    <ImageAndContents class="w-100p mr-1" v-else-if="section.type === 'material' && section.material_type === 'image'" :type="section.material_type" :items="section" :url="section.material_url"/>
                                   <div v-else-if="section.type === 'button'"  class="w-100p">
                                       <Button 
                                           :label="section.button_name" 
                                           class="mx-0 mt-4 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
                                           @click="window.open(section.button_url, '_blank');"
                                       >
                                       </Button>
                                   </div>

                                    <div v-else-if="section.type === 'task'" class="flex justify-between w-100p flex-wrap">
                                        <ImageAndContents class="w-48p mr-1" 
                                            v-for="(task, j) in section.tasks" 
                                            :key="j"  
                                            :items="task"
                                            :type="''" 
                                        />
                                    </div>
                                    <md-icon class="text-2xl pointer" :class="completedSections.filter(value => value.id === section.id).length > 0 || section.is_completed ? 'text-success' : 'text-gray-500'" @click.native="onSelectionMarkAsComplete(part, section)">check_circle</md-icon>
                                    <md-icon class="text-2xl md-theme-default pointer" :class="[sectionBookMarkColorClass(section)]" @click.native="onUpdateSectionBookMark(index, section, i)">bookmark</md-icon>
                                </div>

                            </div>
                            <md-checkbox class="my-3 font-semibold" v-model="part.is_completed">Mark as Completed</md-checkbox>
                        </div>
    
                        <div class="mr-12 mt-10">
                            <ChapterDetailsActionButton />
                        </div>
                    </div>

                    <div v-else class="flex flex-col justify-center align-center" style="min-height: 60vh;">
                        <md-icon class="text-gray-400 text-10xl md-theme-default">lock</md-icon>
                        <span class="relative text-gray-500 text-base font-semibold" style="bottom: 8rem !important;">Click Here To Open This Chapetr</span>
                    </div>
                </div>
            </div>
        </section>

        <md-divider v-if="!isLock" class="my-3 bg-light-blue"></md-divider>

        <section v-if="!isLock" class="flex flex-row justify-end">
            <div class="flex flex-row justify-between w-35p align-center mr-16">
                <span class="text-algal font-semibold text-lg">Discussion (53)</span>
                <Button
                    class="h-12 border border-solid border-algal pointer text-transform-none bg-transparent rounded-xl  px-1 py-2 text-xs font-semibold"
                    type="button"
                    label="Contact With Trainer"    
                    @click.native="goToHome()"
                >
                    <template #prefix-icon>
                        <md-icon class="text-xs mr-1 text-algal">chat</md-icon>
                    </template>
                </Button>
            </div>
        </section>
    </div>
</template>

<script>
import { Button } from "@/components";
import HeaderBreadCrumbs from "@/components/molecule/online-class/student/online-class/chapter-details/HeaderBreadCrumbs.vue"
import ChapterAudio from "@/components/molecule/online-class/items/Audio.vue";
import ImageAndContents from "@/components/molecule/online-class/items/ImageAndContents.vue";
import Videos from "@/components/molecule/online-class/student/Video.vue";
import ChapterProgress from "@/components/molecule/online-class/student/online-class/chapter-details/ChapterProgress.vue";
import ChapterDetailsActionButton from "@/components/molecule/online-class/student/online-class/chapter-details/ChapterDetailsActionButtons.vue";
import ChapterDetailsSideNavbar from "@/components/molecule/online-class/student/online-class/chapter-details/ChapterDetailsSideNavbar.vue";
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
    name: "ChapterDetails",

    components: {
        ChapterDetailsSideNavbar,
        Button,
        HeaderBreadCrumbs,
        ChapterAudio,
        ImageAndContents,
        Videos,
        ChapterProgress,
        ChapterDetailsActionButton,
    },

    data(){
        return {
            mark_as_completed: false,
            isLock: false,
            chapterLoading: false,
            moduleLoading: false,
            completedSections: [],
        }
    },
    computed:{
        ...mapGetters({
            getClassDetail: "onlineClassStudentPart/getClassDetail",
            getSelectedModule: "onlineClassStudentPart/getSelectedModule",
            getSelectedChapterDetail: "onlineClassStudentPart/getSelectedChapterDetail",
        }),
    },
    methods: {
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
            setSelectedModuleChapterDetail: "onlineClassStudentPart/setSelectedModuleChapterDetail"
		}),
        ...mapActions({
            fetchModuleDetailsByClassId: "onlineClassStudentPart/fetchModuleDetailsByClassId",
            fetchChapterDetailByClassIdAndModuleIdAndChapterId: "onlineClassStudentPart/fetchChapterDetailByClassIdAndModuleIdAndChapterId",
            onMarkAsComplete: "onlineClassStudentPart/onMarkAsComplete",
            onBulkMarkAsComplete: "onlineClassStudentPart/onBulkMarkAsComplete",
            onSetBookMark: "onlineClassStudentPart/onSetBookMark"
        }),
        sectionBookMarkColorClass(section) {
            if(section && section?.is_bookmarked) {
                return 'text-tahiti-gold'
            }
            return 'text-gray-500';
        },
        onUpdateSectionBookMark(partIndex, section, sectionIndex) {
            console.log(partIndex, section.id, sectionIndex);

            let data = {
                online_class_id: this.$route?.params.id,
                module_id: this.$route.query?.moduleId,
                chapter_id: this.getSelectedChapterDetail?.id,
                part_id: this.getSelectedChapterDetail?.parts[partIndex]?.id,
                section_id: section.id, 
            };

            this.onSetBookMark(data)
            .then((response) => {
                this.setShowSnackbar(response.message);
                let cloneChapterDetail = JSON.parse(JSON.stringify(this.getSelectedChapterDetail));
                
                cloneChapterDetail.parts[partIndex].sections[sectionIndex].is_bookmarked = this.getSelectedChapterDetail.parts[partIndex].sections[sectionIndex].is_bookmarked ? 0 : 1;
                let obj = {
                    chapter: cloneChapterDetail,
                    moduleId: data.module_id,
                }
                this.setSelectedModuleChapterDetail(obj);
            })
            .catch((error) => {
                this.setShowSnackbar(error?.message);
            });
        },
        onSelectionMarkAsComplete(part, section){
            let sectionObj = {
                partId: part.id,
                ...section
            };

            let index = this.completedSections.findIndex((item) => item.id === section.id);

            if(index >-1) {
                this.completedSections.splice(index, 1);
                // this.completedSections[index] = sectionObj;
            } else {
                this.completedSections.push(sectionObj);
            }


            let data = {
                "class_id": this.getClassDetail.id,
                "type":"sections",
                "type_id": section.id,
                "status": section.is_completed ? "0" : "1",
            };
            this.chapterLoading = true;
            this.onMarkAsComplete(data)
                .then((response) => {
                    this.setShowSnackbar(response.message);
                })
                .catch((error) => {
                    console.log("error", error);
                    this.setShowSnackbar(error?.message);
                })
                .finally(() => this.chapterLoading = false);
        },
        onBack() {
            this.$router.go(-1);
        },

        goToHome() {
            this.$router.push({
                name: 'student.onlineClass'
            });
        },
        fetchModuleDetailByClassId() {
            let classId= this.$route?.params?.id;
            this.moduleLoading = true;

            this.fetchModuleDetailsByClassId(classId)
                .then(() => {
                    let idObj = {
                        classId: this.$route.params.id,
                        moduleId: this.$route.query.moduleId,
                        chapterId: this.$route.query.chapterId,
                    }
                    this.fetchChapterDetail(idObj);
                })
                .catch((error) => {
                    console.log("error", error);
                    this.setShowSnackbar(error);
                })
                .finally(() => this.moduleLoading = false);
        },
        fetchChapterDetail(idObj) {
            this.chapterLoading = true;
            
            this.fetchChapterDetailByClassIdAndModuleIdAndChapterId(idObj)
                .then()
                .catch((error) => {
                    console.log("error", error);
                    this.setShowSnackbar(error?.message);
                })
                .finally(() => this.chapterLoading = false);
        }
    },
    beforeMount(){
        this.fetchModuleDetailByClassId();
    },
    watch: {
        $route:{
            deep: true,
            handler(val) {
                if(val) {
                    let idObj = {
                        classId: val.params.id,
                        moduleId: val.query.moduleId,
                        chapterId: val.query.chapterId,
                    };

                    this.fetchChapterDetail(idObj);
                }
            }
        }
    }
}
</script>