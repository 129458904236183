export const getYTId = (url) => {
  const parsedUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return (parsedUrl[2] !== undefined) ? parsedUrl[2].split(/[^0-9a-z_-]/i)[0] : parsedUrl[0];
}

export const getVimeoId = (url) => {
  const m = url.match(/^.+vimeo.com\/(.*\/)?([^#?]*)/);
  return m ? m[2] || m[1] : null;
}

export const getEmbeddedUrl = (url) => {
  if (url?.includes("youtube")) {
      const id = getYTId(url);
      return `https://www.youtube.com/embed/${id}`
  } else if (url?.includes("vimeo")) {
      const id = getVimeoId(url);
      return `https://player.vimeo.com/video/${id}`
  } else {
      return null;
  }
}