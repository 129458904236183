<template>
<div class="flex align-center justify-between px-1 pointer">
    <div v-if="loading">
        <Loader />
    </div>

    <div class="w-33p" style="max-width: 35% !important;">
        <div class="inline-flex align-center">
            <md-icon class="mr-1 text-xs md-theme-default text-autumn-blaze bg-wheat rounded-full" style="transform: rotate(45deg);">
                attach_file
            </md-icon>
    
            <div class="w-12 h-8 mr-1">
                <img class="rounded h-4 mr-2 imageCustomClass" :src="classImageUrl"/>
            </div>
    
            <div class="mt-1">
                <div>
                    <h2 class="text-xs text-gray font-semibold">CHAPTER</h2>
                </div>
                <div class="mt-1">
                    <p class="text-base font-bold text-black">{{chapterTitlle}}</p>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!isLock" class="w-30p flex justify-between cursor inline-flex px-3 align-center border rounded-full" style="border:1px solid #ced5f4;background-color: #d4eafa; padding-top: 2.5px; padding-bottom: 2.5px;">
        <p class="text-sm font-medium font-inter flex align-center">
            <md-icon class="m-0 text-base text-purply-magenta">schedule</md-icon><span class="text-xs">{{ senitizeDurationToHour }} Hours</span> 
        </p>

        <p class="text-sm font-medium font-inter flex align-center">
            <md-icon class="text-red text-base md-theme-default">cases</md-icon> 
            <span class="text-sm">{{ totalMaterials }}</span>
        </p>

        <p class="text-sm font-medium font-inter flex align-center">
            <md-icon class="text-irish-blue text-base md-theme-default">content_paste</md-icon> 
            <span class="text-xs">{{ totalPracticeTest }}</span>
        </p>

        <p class="text-sm font-medium font-inter flex align-center">
            <md-icon class="text-purply-magenta text-base md-theme-default">article</md-icon>
            <span class="text-xs">{{ totalQuizTest }}</span>
        </p>
    </div>

    <div class="flex align-center justify-end w-35p">
        <LinearProgressBar 
            v-if="!isLock"
            :progressValue="completionRate" 
            label="Completed" 
            class="w-100p mr-2"
            progressbarMinHeight="10px"
            progressbarStripeMinHeight="10px"
            wrapperClass="py-1"
        />
        <md-icon v-if="!isLock" class="cursor text-2xl md-theme-default" :class="bookMarkColorClass" @click.native="onUpdateBookMark">bookmark</md-icon>
        <md-icon v-else class="text-gray-500 text-lg md-theme-default" style="display: contents;">lock</md-icon>
    </div>
</div>
</template>

<script>
import { secondToHour } from '@/utils';
import LinearProgressBar from "@/components/atom/LinearProgressBar.vue";
import defaultImage from "@/assets/images/news_placeholder_detailed_page.png";
import { mapActions, mapMutations } from 'vuex';
import Loader from '@/components/atom/loader.vue';

export default {
    name: "ChapterProgress",
    components: {
        LinearProgressBar,
        Loader
    },
    props: {
        isLock: {
            type: Boolean,
            default: false,
        },
        classDetails: {
            type: Object,
            default: null,
        },
        chapterDetail: {
            type: Object,
            default: null,
        }
    },
    data(){
        return {
            loading: false,
        }
    },
    computed: {
        bookMarkColorClass() {
            if(this.chapterDetail && this.chapterDetail?.is_bookmarked) {
                return 'text-tahiti-gold'
            }

            return 'text-gray-500';
        },
        classImageUrl() {
            if(this.classDetails && this.classDetails.thumbnail) {
                return this.classDetails.thumbnail;
            }
            return defaultImage;
        },
        chapterTitlle() {
            return this.chapterDetail?.title;
        },
        senitizeDurationToHour() {
            if(this.chapterDetail?.duration) {
                return secondToHour(this.chapterDetail.duration)
            }
            return '0';
        },
        totalMaterials(){
            if(this.chapterDetail?.total_materials) {
                return this.chapterDetail.total_materials;
            }

            return "0"
        },
        totalPracticeTest(){
            if(this.chapterDetail?.total_practice_tests) {
                return this.chapterDetail.total_practice_tests;
            }

            return "0"
        },
        totalQuizTest(){
            if(this.chapterDetail?.total_quizes) {
                return this.chapterDetail.total_quizes;
            }

            return "0"
        },
        completionRate() {
            if(this.chapterDetail?.completion_rate) {
                return this.chapterDetail.completion_rate
            }

            return 0;
        },
    },
    methods: {
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
            setSelectedModuleChapterDetail: "onlineClassStudentPart/setSelectedModuleChapterDetail"
		}),
        ...mapActions({
            onSetBookMark: "onlineClassStudentPart/onSetBookMark"
        }),
        onUpdateBookMark(){
            let data = {
                online_class_id: this.$route.params.id,
                module_id: this.$route.query.moduleId,
                chapter_id: this.chapterDetail.id,
                part_id: "",
                section_id: "", 
            };

            this.loading = true;

            this.onSetBookMark(data)
            .then((response) => {
                this.setShowSnackbar(response.message);
                let cloneChapterDetail = JSON.parse(JSON.stringify(this.chapterDetail));
                
                cloneChapterDetail.is_bookmarked = this.chapterDetail.is_bookmarked ? 0 : 1;
                let obj = {
                    chapter: cloneChapterDetail,
                    moduleId: data.module_id,
                }
                this.setSelectedModuleChapterDetail(obj);
            })
            .catch((error) => {
                this.setShowSnackbar(error?.message);
            })
            .finally(() => this.loading = false)
        },
    }
}
</script>

<style lang="scss" scoped>
.chapter-title-truncate {
    background-color: red;
    width: calc(100% + 45px) !important;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
</style>