<template>
<div class="flex justify-between">
    <Button
        class="h-12 pointer text-transform-none text-white bg-autumn-blaze rounded-xl  px-2 py-2 text-xs font-semibold"
        type="button"
        label="Previous Chapter"    
        @click="onPrevious()"
        :disabled="prevChapterBtnDisable"
    >
        <template #prefix-icon>
            <md-icon class="text-2xl mr-1 text-white">arrow_back</md-icon>
        </template>
    </Button>

    <Button
        class="h-12 pointer text-transform-none text-white bg-success rounded-xl  px-2 py-2 text-xs font-semibold"
        type="button"
        label="Mark as Completed and Proceed to the next chapter"   
        @click="onMarkAsCompletedAndNextChapter" 
    >
        <template #prefix-icon>
            <md-icon class="text-2xl mr-1 text-white">check_circle</md-icon>
        </template>
    </Button>

    <Button
        class="h-12 pointer text-transform-none text-white bg-autumn-blaze rounded-xl  px-2 py-2 text-xs font-semibold"
        type="button"
        label="Next Chapter"    
        @click="onNext()"
        :disabled="nextChapterBtnDisable"
    >
        <template #suffix-icon>
            <md-icon class="text-2xl mr-1 text-white">arrow_forward</md-icon>
        </template>
    </Button>
    <div v-if="chapterLoading">
        <Loader />
    </div>
</div>
</template>

<script>
import { Button } from "@/components";
import { mapMutations, mapGetters, mapActions } from 'vuex';
import Loader from '@/components/atom/loader.vue';

export default {
    name: "ChapterDetailsActionButton",
    components: {
        Button,
        Loader,
    },
    data(){
        return{
            chapterLoading: false,
            nextChapterBtnDisable: false,
            prevChapterBtnDisable: false,
        }
    },
    computed:{
        ...mapGetters({
            getClassDetail: "onlineClassStudentPart/getClassDetail",
            getAllModules: "onlineClassStudentPart/getAllModules",
            getSelectedModule: "onlineClassStudentPart/getSelectedModule",
            getSelectedChapterDetail: "onlineClassStudentPart/getSelectedChapterDetail",
            getSelectedChapter: "onlineClassStudentPart/getSelectedChapter",

            getSelectedSideNavModule: "onlineClassStudentPart/getSelectedSideNavModule",
        }),
    },
    methods: {
        ...mapMutations({
            setShowSnackbar: "setShowSnackbar",
			setSideNavSelectedSideNavModule: "onlineClassStudentPart/setSideNavSelectedSideNavModule",
			setSelectedChapter: "onlineClassStudentPart/setSelectedChapter",
		}),
        ...mapActions({
            onBulkMarkAsComplete: "onlineClassStudentPart/onBulkMarkAsComplete",
        }),
        onPrevious(){
            if(this.nextChapterBtnDisable) {
                this.nextChapterBtnDisable = false;
            }
            let totalModules = this.getAllModules.length;
            let currentModuleIndex = this.getAllModules.findIndex((item) => item.id === this.getSelectedSideNavModule.id);
            let totalChaptersOnThisCurrentModule = this.getAllModules[currentModuleIndex].chapters.length;
            let currentChapterIndex = this.getAllModules[currentModuleIndex].chapters.findIndex((chapter) => chapter.id === this.getSelectedChapter.id);

            if(currentModuleIndex == 0 && currentChapterIndex == 0) {
                console.log("Cong");
            } else if(totalModules > currentModuleIndex && totalChaptersOnThisCurrentModule === currentChapterIndex + 1) {
                this.setSideNavSelectedSideNavModule(this.getAllModules[currentModuleIndex]);

                this.setSelectedChapter(this.getAllModules[currentModuleIndex].chapters[currentChapterIndex - 1]);
                if(this.getSelectedSideNavModule.id && this.getSelectedChapter.id) {
                    this.$router.push({
                        query: {
                            moduleId: this.getSelectedSideNavModule.id,
                            chapterId: this.getSelectedChapter.id
                        }
                    });
                }

                if(currentModuleIndex === 0 && totalChaptersOnThisCurrentModule === currentChapterIndex + 1) {
                    this.prevChapterBtnDisable = true;
                }
                
            }  else if(totalModules > currentModuleIndex && totalChaptersOnThisCurrentModule > currentChapterIndex) {
                this.setSideNavSelectedSideNavModule(this.getAllModules[currentModuleIndex - 1]);
                let currentModuleChapterNumber = this.getSelectedModule.chapters.length;
                this.setSelectedChapter(this.getAllModules[currentModuleIndex - 1].chapters[currentModuleChapterNumber - 1]);

                if(this.getSelectedSideNavModule.id && this.getSelectedChapter.id) {
                    this.$router.push({
                        query: {
                            moduleId: this.getSelectedSideNavModule.id,
                            chapterId: this.getSelectedChapter.id
                        }
                    });
                }

            } else if(totalModules  ==  currentModuleIndex + 1 && currentChapterIndex == 0) {
                this.setSideNavSelectedSideNavModule(this.getAllModules[currentModuleIndex - 1]);
                let currentModuleChapterNumber = this.getSelectedModule.chapters.length;
                this.setSelectedChapter(this.getAllModules[currentModuleIndex - 1].chapters[currentModuleChapterNumber - 1]);

                if(this.getSelectedSideNavModule.id && this.getSelectedChapter.id) {
                    this.$router.push({
                        query: {
                            moduleId: this.getSelectedSideNavModule.id,
                            chapterId: this.getSelectedChapter.id
                        }
                    });
                }
            }
        },

        onSubNext() {
            if(this.prevChapterBtnDisable) {
                this.prevChapterBtnDisable = false;
            }

            let totalModules = this.getAllModules.length;
            let currentModuleIndex = this.getAllModules.findIndex((item) => item.id === this.getSelectedSideNavModule.id);
            let totalChaptersOnThisCurrentModule = this.getAllModules[currentModuleIndex].chapters.length;
            let currentChapterIndex = this.getAllModules[currentModuleIndex].chapters.findIndex((chapter) => chapter.id === this.getSelectedChapter.id);

            if(totalModules > currentModuleIndex + 1 && totalChaptersOnThisCurrentModule > currentChapterIndex + 1) {
                this.setSideNavSelectedSideNavModule(this.getAllModules[currentModuleIndex]);
                this.setSelectedChapter(this.getAllModules[currentModuleIndex].chapters[currentChapterIndex + 1]);

                if(this.getSelectedSideNavModule.id && this.getSelectedChapter.id) {
                    this.$router.push({
                        query: {
                            moduleId: this.getSelectedSideNavModule.id,
                            chapterId: this.getSelectedChapter.id
                        }
                    });
                }
            } else if(totalModules > currentModuleIndex + 1) {
                this.setSideNavSelectedSideNavModule(this.getAllModules[currentModuleIndex + 1]);
                this.setSelectedChapter(this.getAllModules[currentModuleIndex + 1].chapters[0]);

                if(this.getSelectedSideNavModule.id && this.getSelectedChapter.id) {
                    this.$router.push({
                        query: {
                            moduleId: this.getSelectedSideNavModule.id,
                            chapterId: this.getSelectedChapter.id
                        }
                    });
                }
            } else if(totalModules >= currentModuleIndex + 1 && totalChaptersOnThisCurrentModule > currentChapterIndex + 1) {
                this.setSelectedChapter(this.getAllModules[currentModuleIndex].chapters[currentChapterIndex + 1]);

                if(this.getSelectedSideNavModule.id && this.getSelectedChapter.id) {
                    this.$router.push({
                        query: {
                            moduleId: this.getSelectedSideNavModule.id,
                            chapterId: this.getSelectedChapter.id
                        }
                    });
                }

                if(totalModules === currentModuleIndex + 1 && totalChaptersOnThisCurrentModule === currentChapterIndex + 2) {
                    this.nextChapterBtnDisable = true;
                }
            } else {
                this.$router.push({
                    name: 'student.onlineClass.congratulations'
                })
                // console.log("Cong");
            }
        },
        onNext() {
            this.onSubNext();
        },
        onMarkAsCompletedAndNextChapter() {
            let data = {
                "class_id": this.getClassDetail.id,
                "chapter_id": this.getSelectedChapter.id,
                "type":"part",
                "type_id": [],
                "status": "1",
            };

            this.getSelectedChapterDetail.parts.forEach((part) => {
                data.type_id.push(part.id);
            });

            this.chapterLoading = true;

            this.onBulkMarkAsComplete(data)
                .then((response) => {
                    this.setShowSnackbar(response.message);
                    this.onSubNext();
                })
                .catch((error) => {
                    console.log("error", error);
                    this.setShowSnackbar(error?.message);
                })
                .finally(() => this.chapterLoading = false);
        }
    }
}
</script>