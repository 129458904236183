<template>
    <div class="flex flex-row justify-between px-3 align-center border rounded-full" :class="wrapperClass" style="border:1px solid #ced5f4;background-color: #d4eafa;">
        <span class="text-xs font-bold text-purply-magenta">{{ label }}</span>
        
        <div class="mx-5 progress progress-lg progress-striped active w-72p" style="margin-top: 2px" :style="{height: progressbarMinHeight}">
            <div role="progressbar progress-striped" :style="{width: progressValue + '%', minHeight: progressbarStripeMinHeight}" class="progress-bar"></div>
        </div>

        <span v-if="isShowProgressValue" class="text-xs font-bold text-purply-magenta">{{ progressValue }}%</span>
   </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Completion Rate'
        },
        isShowProgressValue: {
          type: Boolean,
          default: true,
        },
        progressValue: {
            type: Number,
            default: 10,
        },
        wrapperClass: {
          type: String,
          default: "py-2"
        },
        progressbarMinHeight: {
          type: String,
          default: '16px'
        },
        progressbarStripeMinHeight: {
          type: String,
          default: '16px'
        }
    }
}
</script>

<style lang="scss">
.progress {
  background-color: #c6d5f6;
  border-radius: 15px;
  box-shadow:none;
  &.progress-lg {
    height: 16px;
  }
  &.vertical {
    position: relative;
    width: 20px;
    height: 10px;
    display: inline-block;
    margin-right: 10px;
    > .progress-bar {
      width: 100%!important;
      position: absolute;
      bottom: 0;
    }
    &.progress-lg {
      width: 30px;
    }
  }
}

.progress-bar {
  // background-color: #a221bf;
  background-image: linear-gradient(to right, #a221bf , #461adb) !important;
  box-shadow:none;
  min-height: 16px;
  border-radius: 15px;
  &.text-left{
    text-align: left;
    span{
      margin-left: 10px;
    }
  }
  &.text-right{
    text-align: right;
    span{
      margin-right: 10px;
    }
  }
}
@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 145deg) {
  background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

@-webkit-keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}

// Spec and IE10+
@keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
       -o-animation: $animation;
          animation: $animation;
}
.progress.active .progress-bar,
.progress-bar.active {
  // @include animation(progress-bar-stripes 2s linear infinite);
}
.progress-striped .progress-bar,
.progress-bar-striped {
  @include gradient-striped;
  // background-size: 20px 20px;
}
@mixin progress-bar-variant($color) {
  background-color: $color;
}
</style>