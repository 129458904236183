<template>
    <div class="outline-gray-400 rounded-lg pb-2">
        <div class="chapter-details bg-light-blue-400 px-3 py-2">
            <span class="flex align-center justify-between">
                <p class="font-medium text-sm text-blue">Overall Progress</p>
                <small class="text-sm text-blue font-bold">{{  getClassDetail?.completion_rate }}%</small>
            </span>

            <div class="progress progress-lg progress-striped active w-100p my-2" style="margin-top: 2px" :style="{height: 10 + 'px'}">
                <div role="progressbar progress-striped" :style="{width: getClassDetail?.completion_rate + '%', minHeight: 10+'px'}" class="chapter-detail-progress-bar"></div>
            </div>
        </div>

        <div class="ml-4 mr-2">
            <div class="inline-flex">
                <md-icon class="mr-1 text-blue text-sm md-theme-default text-success">
                    content_paste
                </md-icon>
    
                <div class="mt-1">
                    <div>
                        <p class="text-xs text-gray">Class Name</p>
                    </div>
                    <div class="">
                        <p class="text-xs font-semibold text-black">{{ getClassDetail?.title }}</p>
                    </div>
                </div>
            </div>

            <div class="mt-2" v-for="(moduleObj, index) in getAllModules" :key="moduleObj.id+index">
                <div class="rounded-lg" :class="[currentModule === moduleObj.id ? 'bg-light-blue-300 py-1' : 'bg-white', currentModule === moduleObj.id ? 'border border-solid border-light-blue' : '' ]">
                    <div class="flex justify-between align-center p-1 mr-6">
                        <div class="flex pl-3">
                            <md-icon class="mr-1 text-blue text-sm md-theme-default">
                                book
                            </md-icon>
                
                            <div class="">
                                <div>
                                    <p class="text-xs text-gray">Module</p>
                                </div>
                                <div class="">
                                    <p class="text-xs text-black" :class="currentModule === moduleObj.id ? 'font-semibold' : 'font-normal'">{{ moduleObj.title }}</p>
                                </div>
                            </div>
                        </div>

                        <md-icon class="m-0  text-light-blue font-normal pointer" v-if="currentModule === moduleObj.id && isOpen" @click.native="onCollapseMenue">remove</md-icon>
                        <md-icon class="m-0  text-light-blue font-normal pointer" v-else @click.native="onSelectedModule(moduleObj, index)">menu</md-icon>
                    </div>

                    <div class="md-layout ml-4" v-if="currentModule === moduleObj.id && isOpen">
                        <div class="md-layout-item md-size-100 my-1" v-for="chapter in moduleObj?.chapters" :key="chapter.id">
                            <div class="flex align-center justify-between  p-1 pointer" @click="onSelectChapter(moduleObj, chapter, index)">
                                <div class="flex pl-3">
                                    <md-icon class="mr-1 text-autumn-blaze text-sm md-theme-default" style="transform: rotate(45deg);">
                                        attach_file
                                    </md-icon>
                        
                                    <div>
                                        <div>
                                            <p class="text-xs text-gray">Chapter</p>
                                        </div>
                                        <div class="">
                                            <p class="text-sm font-normal text-black" :class="currentModule === moduleObj.id && currentChapter === chapter.id ? 'font-semibold' : 'font-normal'">{{ chapter.title }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <md-checkbox class="my-3 font-semibold" v-model="chapter.complete"></md-checkbox> -->
                            </div>

                            <div class="md-layout ml-1 mt-1" v-if="currentChapter === chapter.id">
                                <div class="md-layout-item md-size-100" v-for="(part, p) in chapter.parts" :key="part.id">
                                    <div class="flex align-center p-1 pointer" @click="onSelectPart(moduleObj, chapter, part, p)">
                                        <div class="flex pl-3">
                                            <md-icon 
                                                class="text-purply-magenta text-sm"
                                                style="transform: rotate(90deg);"
                                            >
                                                {{currentModule === moduleObj.id && currentChapter === chapter.id && currentPart === part.id && p === currentPartIndex ? 'navigation' : ''}}
                                            </md-icon>
                                            <p class="text-xs font-semibold" :class="currentModule === moduleObj.id && currentChapter === chapter.id && currentPart === part.id && p === currentPartIndex ? 'text-purply-magenta font-bold' : ''">{{ part.title }}</p>
                                        </div>
    
                                        <!-- <md-icon class="text-success text-xs">check_circle</md-icon> -->
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    name: "ChapterDetailsSideNavbar",
    data() {
        return{
            classDeatail: {
                id: "class1",
                title: "Importance of English",
                progress: 23,
                modules: [
                    {
                        id: "module1",
                        title: "What is Language",
                        chapters: [
                            {
                                id: "chapter1",
                                chapter: "Chapter One",
                                title: "How Language Come?",
                                complete: false,
                                parts: [
                                    {
                                        id: "part1",
                                        title: "Title of The Part"
                                    },
                                    {
                                        id: "part2",
                                        title: "Title of The Part"
                                    },
                                    {
                                        id: "part3",
                                        title: "Title of The Part"
                                    },
                                    {
                                        id: "part4",
                                        title: "Title of The Part"
                                    },
                                ],
                            },
                            {
                                id: "chapter2",
                                chapter: "Chapter Two",
                                title: "Who Use Language?",
                                complete: false,
                                parts: [
                                    {
                                        id: "part1",
                                        title: "Title of The Part"
                                    },
                                    {
                                        id: "part2",
                                        title: "Title of The Part"
                                    },
                                    {
                                        id: "part3",
                                        title: "Title of The Part"
                                    },
                                    {
                                        id: "part4",
                                        title: "Title of The Part"
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        id: "module2",
                        title: "What is language?"
                    },
                    {
                        id: "module3",
                        title: "What is language?"
                    },
                    {
                        id: "module4",
                        title: "What is language?"
                    }
                ]
            },
            current: 0,
            currentChapter: '',
            isOpen: true,
            currentPart: '',
            currentModule: '',
            currentPartIndex: 0,
            loading: false,
        }
    },
    computed:{
        ...mapGetters({
            getClassDetail: "onlineClassStudentPart/getClassDetail",
            getSelectedSideNavModule: "onlineClassStudentPart/getSelectedSideNavModule",
            getAllModules: "onlineClassStudentPart/getAllModules",
            getSelectedChapter: "onlineClassStudentPart/getSelectedChapter",
        }),
    },
    methods: {
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
			setSideNavSelectedSideNavModule: "onlineClassStudentPart/setSideNavSelectedSideNavModule",
            setSelectedChapter: "onlineClassStudentPart/setSelectedChapter",
		}),
        ...mapActions({
            fetchChapterSummaryByClassIdAndModuleId: "onlineClassStudentPart/fetchChapterSummaryByClassIdAndModuleId",
            fetchModulesForSideNavByClassId: "onlineClassStudentPart/fetchModulesForSideNavByClassId"
        }),
        onShowChapterSummary(moduleObject) {
            this.loading = true;
            
            let idObj = {
                classId: this.getClassDetail.id,
                moduleId: moduleObject.id,
            }

            this.fetchChapterSummaryByClassIdAndModuleId(idObj)
                .then(() => {
                    this.isShow = true;
                })
                .catch((error) => {
                    console.log("error", error);
                    this.setShowSnackbar(error?.message);
                })
                .finally(() => this.loading = false);
        },
        onSelectedModule(moduleObj, index){
            // if(!moduleObj.chapters) {
            //     this.onShowChapterSummary(moduleObj);
            // } else if(moduleObj.chapters && moduleObj.chapters.length < moduleObj.total_chapters) {
            //     this.onShowChapterSummary(moduleObj);
            // }
 
            // if(this.current === index) {
            //     this.isOpen = !this.isOpen;
            // } else {
            // }
            this.setSideNavSelectedSideNavModule(moduleObj);
            this.isOpen = true;
            this.currentModule = moduleObj.id;

            this.current = Number(index);
        },
        onCollapseMenue() {
            this.isOpen = false;
            this.currentModule = "";
        },
        onSelectChapter(moduleObj, item, index){
            console.log(index);
            // this.$emit('selectedClass', moduleObj, item, index);
            this.setSelectedChapter(item);
            this.currentModule = moduleObj.id;
            this.currentChapter = item.id;
            this.$router.push({
                query: {
                    moduleId: moduleObj.id,
                    chapterId: item.id
                }
            });
        },

        onSelectPart(moduleObj, chapter, part, p) {
            this.currentModule = moduleObj.id;
            this.currentChapter = chapter.id;
            this.currentPart = part.id;
            this.currentPartIndex = p;
        },
        onFetchModulesForSideNavByClassId() {
            let classId= this.$route?.params?.id;
            this.loading = true;

            this.fetchModulesForSideNavByClassId(classId)
                .then()
                .catch((error) => {
                    console.log("error", error);
                    this.setShowSnackbar(error);
                })
                .finally(() => this.loading = false);
        },
    },
    mounted() {
        this.onFetchModulesForSideNavByClassId();
    },
    watch:{
        getSelectedSideNavModule: {
            immediate: true,
            deep: true,
            handler(val) {
                if(val) {
                    this.currentModule = this.getSelectedSideNavModule && this.getSelectedSideNavModule.id ? this.getSelectedSideNavModule.id : '';
                    if(this.currentModule) {
                        this.isOpen = true;
                    }
                }
            }
        },
        getSelectedChapter: {
            immediate: true,
            deep: true,
            handler(val) {
                if(val) {
                    // console.log("WWWWWWWWWWWWWWWWW");
                    this.currentChapter = this.getSelectedChapter && this.getSelectedChapter.id ? this.getSelectedChapter.id : '';
                    if(this.currentChapter) {
                        this.isOpen = true;
                    }
                }
            }
        }
    },
}
</script>

<style lang="scss">
.chapter-details {
    .chapter-detail-progress-bar {
        background-color: #2E67FC !important; 
        box-shadow:none;
        min-height: 16px;
        border-radius: 15px;
        &.text-left{
            text-align: left;
            span{
            margin-left: 10px;
            }
        }
        &.text-right{
            text-align: right;
            span{
            margin-right: 10px;
            }
        }
    }

}
</style>